define("fitbase/components/auth-reset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CJ6nOzza",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"auth-error\",null,[[\"message\"],[[24,[\"error\"]]]]],false],[4,\"if\",[[24,[\"isSent\"]]],null,{\"statements\":[[1,[28,\"inline-svg\",[\"assets/images/check-mark\"],null],false],[7,\"p\",true],[10,\"class\",\"p\"],[8],[0,\"Check your email!\"],[9],[7,\"p\",true],[10,\"class\",\"p\"],[8],[0,\"If there is an account for \"],[1,[22,\"email\"],false],[0,\", we'll send you a link to reset your password.\"],[9],[7,\"p\",true],[10,\"class\",\"p\"],[8],[0,\"Check your spam folder just in case.\"],[9],[7,\"div\",false],[12,\"class\",\"link\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"doTryAgain\"]],[8],[0,\"Try again\"],[9]],\"parameters\":[]},{\"statements\":[[7,\"form\",false],[3,\"action\",[[23,0,[]],\"doForgot\"],[[\"on\"],[\"submit\"]]],[8],[7,\"p\",true],[10,\"class\",\"p p--left\"],[8],[0,\"Enter your email, and we'll send you instructions to reset your password.\"],[9],[1,[28,\"input-auth\",null,[[\"type\",\"label\",\"value\",\"inputName\",\"placeholder\"],[\"email\",\"Email\",[24,[\"email\"]],\"forgot-email\",\"jesse@example.com\"]]],false],[7,\"button\",true],[10,\"class\",\"button button--med button--primary\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"Send email\"],[9],[9]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/auth-reset/template.hbs"
    }
  });

  _exports.default = _default;
});