define("fitbase/components/client-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EkAhNFsf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"__meta\"],[8],[7,\"span\",true],[10,\"class\",\"__author\"],[8],[1,[22,\"authorName\"],false],[0,\" said\"],[9],[7,\"span\",true],[10,\"class\",\"__ago\"],[8],[0,\" \"],[1,[28,\"moment-weeks-ago\",[[24,[\"message\",\"sentAt\"]]],null],false],[9],[7,\"div\",true],[10,\"class\",\"__time\"],[8],[1,[28,\"moment-format\",[[24,[\"message\",\"sentAt\"]],\"MMM D @ hA\"],null],false],[9],[9],[7,\"div\",true],[10,\"class\",\"__body\"],[8],[1,[24,[\"message\",\"body\"]],false],[14,1],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/client-message/template.hbs"
    }
  });

  _exports.default = _default;
});