define("fitbase/coach/clients/client/messages/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this.sortMessagesBy = ['sentAt:desc'];

      this._super.apply(this, arguments);
    },
    hasClickedLoadMore: false,
    sortedMessages: Ember.computed.sort('model.messages', 'sortMessagesBy')
  });

  _exports.default = _default;
});