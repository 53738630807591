define("fitbase/components/coach-client-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["isShowing"],
    isShowing: false,
    click: function click() {
      this.set('isShowingClientNav', false);
      this.toggleProperty('isShowing');
    }
  });

  _exports.default = _default;
});