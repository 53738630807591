define("fitbase/components/quill", ["exports", "@cloudcreativity/ember-quill/components/quill"], function (_exports, _quill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _quill.default;
    }
  });
});