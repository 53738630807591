define("fitbase/client/model", ["exports", "ember-data", "ember-md5"], function (_exports, _emberData, _emberMd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    email: attr('string'),
    notes: attr('string'),
    notesDelta: attr(),
    state: attr('string'),
    stateUpdatedAt: attr('date'),
    avatar: attr('string'),
    icsDtend: attr('date'),
    lastCoachedAt: attr('date'),
    lastMessageAt: attr('date'),
    hasUncoachedMessage: attr('boolean'),
    checkinForm: belongsTo('checkin-form'),
    user: belongsTo('user'),
    checkinLatest: belongsTo('checkin'),
    checkins: hasMany('checkin'),
    messages: hasMany('checkin-reply'),
    getNotesValue: Ember.computed('notes', 'notesDelta', function () {
      return this.get('notesDelta') ? this.get('notesDelta') : this.get('notes');
    }),
    firstName: Ember.computed('name', function () {
      var name = this.get('name');

      if (name) {
        return name.split(' ')[0];
      }

      return 'Unnamed Client';
    }),
    isEnded: Ember.computed('icsDtend', function () {
      var icsDtend = this.get('icsDtend');
      var now = new Date();
      return icsDtend < now && icsDtend !== null;
    }),
    avatarUrl: Ember.computed('avatar', 'email', function () {
      var avatar = this.get('avatar');

      if (Ember.isEmpty(avatar)) {
        var email = this.get('email').toLowerCase();
        var hash = (0, _emberMd.default)(email);
        return "https://www.gravatar.com/avatar/".concat(hash, "?d=blank");
      }

      return false;
    })
  });

  _exports.default = _default;
});