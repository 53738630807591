define("fitbase/components/quill/toolbar", ["exports", "@cloudcreativity/ember-quill/components/quill/toolbar"], function (_exports, _toolbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _toolbar.default;
    }
  });
});