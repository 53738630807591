define("fitbase/password-reset/model", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    email: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    exp: (0, _attr.default)('date'),
    password: (0, _attr.default)('string'),
    confirmPassword: (0, _attr.default)('string')
  });

  _exports.default = _default;
});