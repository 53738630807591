define("fitbase/auth/reset/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model(params) {
      if (!params.code) {
        alert('Invalid password reset code');
        this.transitionTo('auth.forgot');
        return;
      }

      return this.store.findRecord('PasswordReset', params.code);
    },
    actions: {
      error: function error()
      /*error, transition*/
      {
        alert('Invalid password-reset code. Try the link again, or request another password reset.');
        this.transitionTo('auth.forgot');
      }
    }
  });

  _exports.default = _default;
});