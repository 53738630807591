define("fitbase/checkin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bm3rpDTv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"client-main ember-view\"],[8],[4,\"header-nav/checkin\",null,null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"columns\"],[8],[7,\"div\",true],[10,\"class\",\"column column--single\"],[8],[7,\"div\",true],[10,\"class\",\"checkin-head__client-name\"],[8],[1,[22,\"name\"],false],[9],[7,\"div\",true],[10,\"class\",\"checkin-head__info\"],[8],[7,\"div\",true],[10,\"class\",\"checkin-head__date\"],[8],[0,\"Check-in: \"],[1,[28,\"moment-format\",[[24,[\"checkin\",\"dueAt\"]],\"dd - MMM D, 'YY\"],null],false],[9],[7,\"div\",true],[10,\"class\",\"checkin-head__number\"],[8],[0,\"Last updated: \"],[1,[28,\"moment-format\",[[24,[\"checkin\",\"updatedAt\"]],\"MMM D @ hA\"],[[\"timeZone\"],[\"America/Los_Angeles\"]]],false],[9],[9],[9],[9],[1,[28,\"checkin-intro\",null,[[\"checkin\",\"percent\"],[[24,[\"checkin\"]],[24,[\"percentFilled\"]]]]],false],[1,[28,\"checkin-form-progress\",null,[[\"percent\"],[[24,[\"percentFilled\"]]]]],false]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"scroll-wrap\"],[8],[7,\"div\",true],[10,\"class\",\"scroll\"],[8],[7,\"div\",true],[10,\"class\",\"columns\"],[8],[7,\"div\",true],[10,\"class\",\"column column--single\"],[8],[1,[28,\"checkin-form\",null,[[\"checkin\",\"subject\",\"edit\",\"checkinValues\",\"savePosition\",\"save\",\"submit\",\"showSummary\"],[[24,[\"checkin\"]],[24,[\"subject\"]],[24,[\"edit\"]],[24,[\"checkin\",\"checkinValues\"]],[28,\"route-action\",[\"savePosition\"],null],[28,\"route-action\",[\"save\"],null],[28,\"route-action\",[\"submit\"],null],[28,\"route-action\",[\"showSummary\"],null]]]],false],[9],[9],[1,[22,\"outlet\"],false],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/checkin/template.hbs"
    }
  });

  _exports.default = _default;
});