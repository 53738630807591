define("fitbase/coach/clients/client/messages/route", ["exports", "fitbase/mixins/authenticated-trainer-route-mixin"], function (_exports, _authenticatedTrainerRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedTrainerRouteMixin.default, {
    infinity: Ember.inject.service(),
    model: function model() {
      var client = this.modelFor('coach.clients.client');
      return {
        messages: this.infinity.model('message', {
          client_id: client.id,
          perPage: 2
        })
      };
    } //model(params) {
    //const client = this.modelFor('coach.clients.client');
    //return client;
    ////let messages = client.hasMany('messages').reload();
    //let earlier_than_id = null;
    //let messages = this.store.query('message', {
    //client_id: client.id,
    //filter: {
    //client_id: client.id,
    //earlier_than_id: earlier_than_id
    //}
    //});
    //return messages;
    //},
    //setupController(controller) {
    //this._super(...arguments);
    ////controller.setProperties({
    ////clients: this.modelFor('coach.clients')
    ////});
    //},
    //actions: {
    //error(reason) {
    //console.log(reason);
    //if (reason instanceof DS.AdapterError && reason.errors) {
    //console.log(reason.errors[0].detail);
    //if (reason.errors[0].status && reason.errors[0].status == 404) {
    //this.transitionTo('clients.index');
    //}
    //}
    //},
    //}

  });

  _exports.default = _default;
});