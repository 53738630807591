define("fitbase/coach/settings/profile/route", ["exports", "fitbase/mixins/authenticated-trainer-route-mixin"], function (_exports, _authenticatedTrainerRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedTrainerRouteMixin.default, {
    model: function model() {
      return this.modelFor('coach.settings');
    },
    _routeModel: function _routeModel() {
      return this.modelFor(this.routeName);
    }
  });

  _exports.default = _default;
});