define("fitbase/coach/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SBu6vcAT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"coach-container \",[28,\"if\",[[24,[\"isShowingCoachNav\"]],\"is-showing-coach-nav\"],null]]]],[8],[4,\"coach-head-bar\",null,null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"fsp-brand\"],[8],[1,[28,\"inline-svg\",[\"assets/images/fitspacepro-icon\"],[[\"class\"],[\"fsp-icon\"]]],false],[1,[28,\"inline-svg\",[\"assets/images/fitspacepro-word\"],[[\"class\"],[\"fsp-word\"]]],false],[9],[7,\"div\",true],[10,\"class\",\"coach-brand\"],[8],[9],[7,\"div\",true],[10,\"class\",\"coach-account-links\"],[8],[4,\"if\",[[24,[\"currentUser\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"header-buttons\"],[8],[7,\"div\",true],[10,\"class\",\"header__user header-button\"],[8],[1,[24,[\"currentUser\",\"email\"]],false],[9],[7,\"a\",false],[12,\"class\",\"header__logout header-button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"logout\"]],[8],[0,\"logout\"],[9],[9]],\"parameters\":[]},null],[9]],\"parameters\":[]},null],[1,[28,\"coach-nav\",null,[[\"isShowing\",\"countClients\",\"countLateClients\",\"countPotentials\"],[[24,[\"isShowingCoachNav\"]],[24,[\"countClients\"]],[24,[\"countLateClients\"]],[24,[\"countPotentials\"]]]]],false],[4,\"coach-main\",null,[[\"isSwiped\"],[[24,[\"isShowingCoachNav\"]]]],{\"statements\":[[1,[22,\"outlet\"],false]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/coach/template.hbs"
    }
  });

  _exports.default = _default;
});