define("fitbase/account/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _relationships.default.hasMany;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    owner: (0, _attr.default)('string'),
    stripeCustomerId: (0, _attr.default)('string'),
    clientCount: (0, _attr.default)('string'),
    stripeDataCache: (0, _attr.default)(),
    stripeDataCacheAt: (0, _attr.default)('date'),
    cardToken: (0, _attr.default)(),
    // relationship
    coaches: hasMany('users')
  });

  _exports.default = _default;
});