define("fitbase/auth/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dt1a018D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"scroll-wrap\"],[8],[7,\"div\",true],[10,\"class\",\"scroll\"],[8],[7,\"div\",true],[10,\"class\",\"auth\"],[8],[7,\"div\",true],[10,\"class\",\"auth__title\"],[8],[7,\"img\",true],[10,\"src\",\"assets/images/emoji-waving_hand.png\"],[10,\"class\",\"emoji-img\"],[8],[9],[7,\"br\",true],[8],[9],[0,\"Welcome back\"],[9],[7,\"div\",true],[10,\"class\",\"auth__subtitle\"],[8],[0,\"It's great to see you again!\"],[9],[7,\"div\",true],[10,\"class\",\"auth__card card\"],[8],[7,\"div\",true],[10,\"class\",\"content auth__content\"],[8],[1,[28,\"auth-error\",null,[[\"message\"],[[24,[\"error\"]]]]],false],[1,[28,\"auth-login\",null,[[\"doLogin\",\"model\"],[[28,\"action\",[[23,0,[]],\"doLogin\"],null],[24,[\"model\"]]]]],false],[9],[9],[7,\"div\",true],[10,\"class\",\"auth__links\"],[8],[4,\"link-to\",null,[[\"class\",\"route\"],[\"link\",\"auth.forgot\"]],{\"statements\":[[0,\"Forgot your password?\"]],\"parameters\":[]},null],[9],[9],[1,[22,\"outlet\"],false],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/auth/login/template.hbs"
    }
  });

  _exports.default = _default;
});