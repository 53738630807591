define("fitbase/coach/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xK2APAgl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"scroll-wrap\"],[8],[7,\"div\",true],[10,\"class\",\"scroll\"],[8],[7,\"div\",true],[10,\"class\",\"page-content columns\"],[8],[7,\"main\",true],[10,\"class\",\"column column--center settings\"],[8],[1,[22,\"outlet\"],false],[9],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/coach/settings/template.hbs"
    }
  });

  _exports.default = _default;
});