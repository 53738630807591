define("fitbase/utils/validations/user", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }),
    email: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true
    }), (0, _validators.validateFormat)({
      type: 'email'
    })],
    password: [(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      on: ['email', 'editPassword']
    }), (0, _validators.validateLength)({
      min: 10,
      max: 24,
      allowBlank: true
    })]
  };
  _exports.default = _default;
});