define("fitbase/auth/register/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord('user');
    },
    actions: {
      doRegister: function doRegister() {
        var _this = this;

        var model = Ember.get(this, 'currentModel');
        var credentials = model.save();
        credentials.then(function (result) {
          console.log('app/auth/register then', result);
          return _this.session.authenticate('authenticator:application', result.email, result.password);
        }).then(function (authed) {
          console.log(authed);
          return authed;
        }).catch(function (error) {
          console.log('error', error);
        }); //console.log('credentials', credentials);
      }
    }
  });

  _exports.default = _default;
});