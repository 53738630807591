define("fitbase/coach/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    clients: Ember.computed.alias('model.clients'),
    currentUser: Ember.computed.alias('session.currentUser'),
    countClients: Ember.computed('clients.@each.{state,isNew}', function () {
      return this.clients.filter(function (client) {
        return client.get('state') !== "archived" && client.get('isNew') == false;
      }).length;
    })
  });

  _exports.default = _default;
});