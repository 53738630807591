define("fitbase/components/nav-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fXCg86du",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"nav-link__icon\"],[8],[1,[28,\"inline-svg\",[[24,[\"iconPath\"]]],null],false],[9],[4,\"if\",[[24,[\"linkTitle\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"nav-link__label\"],[8],[1,[22,\"linkTitle\"],false],[9]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/nav-link/template.hbs"
    }
  });

  _exports.default = _default;
});