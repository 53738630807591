define("fitbase/auth/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rGtcwtlq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"scroll-wrapper\"],[8],[7,\"div\",true],[10,\"class\",\"scroll\"],[8],[1,[22,\"outlet\"],false],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/auth/template.hbs"
    }
  });

  _exports.default = _default;
});