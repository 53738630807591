define("fitbase/auth/register/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vTcfl4HK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"scroll-wrap\"],[8],[7,\"div\",true],[10,\"class\",\"scroll\"],[8],[7,\"div\",true],[10,\"class\",\"auth\"],[8],[7,\"div\",true],[10,\"class\",\"auth__title\"],[8],[7,\"img\",true],[10,\"src\",\"assets/images/emoji-tada.png\"],[10,\"class\",\"emoji-img\"],[8],[9],[7,\"br\",true],[8],[9],[0,\"Sign Up!\"],[9],[7,\"div\",true],[10,\"class\",\"auth__subtitle\"],[8],[0,\"Let's start with just the essentials\"],[9],[7,\"div\",true],[10,\"class\",\"auth__card card\"],[8],[7,\"div\",true],[10,\"class\",\"content auth__content\"],[8],[1,[28,\"auth-registration\",null,[[\"model\",\"doRegister\",\"class\"],[[24,[\"model\"]],[28,\"route-action\",[\"doRegister\"],null],\"auth-registration\"]]],false],[9],[9],[7,\"div\",true],[10,\"class\",\"auth__links\"],[8],[4,\"link-to\",null,[[\"route\"],[\"auth.login\"]],{\"statements\":[[0,\"Log in here\"]],\"parameters\":[]},null],[9],[9],[1,[22,\"outlet\"],false],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/auth/register/template.hbs"
    }
  });

  _exports.default = _default;
});