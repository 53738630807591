define("fitbase/auth/forgot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZoskbZRR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"scroll-wrap\"],[8],[7,\"div\",true],[10,\"class\",\"scroll\"],[8],[7,\"div\",true],[10,\"class\",\"auth\"],[8],[7,\"div\",true],[10,\"class\",\"auth__title\"],[8],[7,\"img\",true],[10,\"src\",\"/assets/images/emoji-flushed.png\"],[10,\"class\",\"emoji-img\"],[8],[9],[7,\"br\",true],[8],[9],[0,\"Forgot?\"],[9],[7,\"div\",true],[10,\"class\",\"auth__subtitle\"],[8],[0,\"Happens to the best of us.\"],[9],[7,\"div\",true],[10,\"class\",\"auth__card card\"],[8],[7,\"div\",true],[10,\"class\",\"content auth__content\"],[8],[5,\"auth-reset\",[],[[],[]],{\"statements\":[],\"parameters\":[]}],[9],[9],[7,\"div\",true],[10,\"class\",\"auth__links\"],[8],[4,\"link-to\",null,[[\"class\",\"route\"],[\"link\",\"auth.login\"]],{\"statements\":[[0,\"Did you remeber? Log in here\"]],\"parameters\":[]},null],[9],[9],[1,[22,\"outlet\"],false],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/auth/forgot/template.hbs"
    }
  });

  _exports.default = _default;
});