define("fitbase/components/text-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oChu/3WQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"text-editor \",[23,0,[\"styleNames\"]]]]],[8],[5,\"quill-editor\",[],[[\"@name\",\"@text\",\"@delta\",\"@onChange\",\"@onEditorChange\",\"@modules\",\"@placeholder\",\"@theme\"],[[23,0,[\"args\",\"name\"]],[23,0,[\"args\",\"text\"]],[23,0,[\"args\",\"delta\"]],[23,0,[\"setChange\"]],[23,0,[\"onEditorChange\"]],[23,0,[\"modules\"]],[23,0,[\"args\",\"placeholder\"]],\"snow\"]],{\"statements\":[],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/text-editor/template.hbs"
    }
  });

  _exports.default = _default;
});