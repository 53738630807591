define("fitbase/components/account-coach/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oxTmtlc8",
    "block": "{\"symbols\":[\"@coach\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"account-coach \",[23,0,[\"css\"]]]]],[8],[7,\"b\",true],[10,\"class\",\"account-coach__name\"],[8],[1,[23,1,[\"name\"]],false],[9],[7,\"div\",true],[10,\"class\",\"account-coach__email\"],[8],[1,[23,1,[\"email\"]],false],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/account-coach/template.hbs"
    }
  });

  _exports.default = _default;
});