define("fitbase/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "fitbase/config/environment", "ember-data"], function (_exports, _applicationRouteMixin, _environment, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    crispChat: Ember.inject.service('crisp-chat'),
    beforeModel: function beforeModel() {
      this.crispChat.load();
    },
    routeAfterAuthentication: Ember.computed('session.subject', function computedRouteAfterAuthentication() {
      var _Ember$get = Ember.get(this, 'session.subject'),
          _Ember$get2 = _slicedToArray(_Ember$get, 2),
          model = _Ember$get2[0],
          id = _Ember$get2[1];

      var start_path = Ember.get(this, 'session.data.start_path');

      switch (model) {
        case 'checkin':
          return "/checkin/".concat(id);

        case 'client':
          if (start_path) {
            return start_path;
          } else {
            return "/checkins";
          }

        default:
          return '/coach/clients';
      }
    }),
    sessionAuthenticated: function sessionAuthenticated() {
      if (_environment.default.environment !== "test") {
        Ember.get(this, 'session').identify();
      }

      return this._super.apply(this, arguments);
    },
    sessionInvalidated: function sessionInvalidated() {
      if (_environment.default.environment !== "test") {
        Ember.get(this, 'session').unidentify();
      }

      return this._super.apply(this, arguments);
    },
    actions: {
      logout: function logout() {
        Ember.get(this, 'session').invalidate();
      },
      error: function error(reason) {
        console.log('application/route error', reason);

        if (reason instanceof _emberData.default.AdapterError && reason.errors && reason.errors[0].status == 404) {
          this.transitionTo('/not-found');
        }
      }
    }
  });

  _exports.default = _default;
});