define("fitbase/coach/clients/client/checkins/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DtFeMJGu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"column column--single\"],[8],[7,\"section\",true],[8],[1,[28,\"client-head\",null,[[\"client\"],[[24,[\"model\",\"client\"]]]]],false],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"link link--strong\",\"coach.clients.client\",[24,[\"model\",\"client\",\"id\"]]]],{\"statements\":[[0,\"Back to overview\"]],\"parameters\":[]},null],[9],[7,\"section\",true],[8],[7,\"header\",true],[8],[7,\"h2\",true],[8],[1,[28,\"inline-svg\",[\"assets/images/icon-chart\"],[[\"class\"],[\"inline-svg\"]]],false],[0,\"Check-in history\"],[9],[9],[4,\"if\",[[24,[\"model\",\"checkinFields\",\"length\"]]],null,{\"statements\":[[1,[28,\"checkins-list\",null,[[\"fields\",\"checkins\"],[[24,[\"model\",\"checkinFields\"]],[24,[\"model\",\"checkins\"]]]]],false]],\"parameters\":[]},{\"statements\":[[0,\"There are no check-in fields to show\"]],\"parameters\":[]}],[9],[1,[22,\"outlet\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/coach/clients/client/checkins/index/template.hbs"
    }
  });

  _exports.default = _default;
});