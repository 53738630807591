define("fitbase/coach/clients/new/controller", ["exports", "fitbase/utils/client-validations", "ember-cp-validations"], function (_exports, _clientValidations, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.name': _clientValidations.fullname,
    'model.email': _clientValidations.email
  });

  var _default = Ember.Controller.extend(Validations, {
    showValidations: false,
    clientName: Ember.computed('model.name', function () {
      return Ember.isEmpty(this.get('model.name')) ? 'New Client' : this.get('model.name');
    }),
    firstName: Ember.computed('model.firstName', function () {
      return Ember.isEmpty(this.get('model.firstName')) ? 'client' : this.get('model.firstName');
    }),
    placeholderNote: Ember.computed('firstName', function () {
      return "Some key things to remember about ".concat(this.get('firstName'), ".");
    }),
    actions: {
      save: function save() {
        if (this.get('validations.isValid')) {
          return true;
        } else {
          this.set('showValidations', true);
        }
      },
      cancelClient: function cancelClient() {
        window.history.back();
      },
      setDelta: function setDelta(delta) {
        this.model.set('notesDelta', delta);
      }
    }
  });

  _exports.default = _default;
});