define("fitbase/coach/clients/client/messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SqsRBZp4",
    "block": "{\"symbols\":[\"message\",\"i\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"column--center\"],[8],[4,\"client-messages\",null,[[\"infinityModel\"],[[24,[\"model\",\"messages\"]]]],{\"statements\":[[4,\"each\",[[24,[\"sortedMessages\"]]],null,{\"statements\":[[1,[28,\"client-message\",null,[[\"message\"],[[23,1,[]]]]],false]],\"parameters\":[1,2]},null],[1,[28,\"infinity-loader\",null,[[\"infinityModel\",\"loadedText\",\"loadingText\"],[[24,[\"model\",\"messages\"]],\"No more messages\",\"Loading more...\"]]],false]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/coach/clients/client/messages/template.hbs"
    }
  });

  _exports.default = _default;
});