define("fitbase/user/adapter", ["exports", "fitbase/application/adapter", "ember-data-url-templates"], function (_exports, _adapter, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_emberDataUrlTemplates.default, {
    urlTemplate: '{+host}/{+namespace}/users{/id}',
    createRecordUrlTemplate: '{+host}/{+namespace}/register'
  });

  _exports.default = _default;
});