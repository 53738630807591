define("fitbase/user/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _relationships.default.hasMany,
      belongsTo = _relationships.default.belongsTo;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    username: (0, _attr.default)('string'),
    uuid: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    confirmPassword: (0, _attr.default)('string'),
    currentPassword: (0, _attr.default)('string'),
    // relationship
    account: belongsTo('account'),
    clients: hasMany('clients')
  });

  _exports.default = _default;
});