define("fitbase/checkin-link/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    routeIfAlreadyAuthenticated: Ember.computed(function () {
      var start_path = Ember.get(this, 'session.data.start_path');
      if (start_path) return start_path;
      return '/' + Ember.get(this, 'session.subject').join('/');
    }),
    model: function model(params) {
      var id = params.id,
          link_id = params.link_id;
      return {
        id: id,
        link_id: link_id
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      if (Ember.get(this, 'session.isAuthenticated')) {
        var _Ember$get = Ember.get(this, 'session.subject'),
            _Ember$get2 = _slicedToArray(_Ember$get, 2),
            subject = _Ember$get2[0],
            id = _Ember$get2[1];

        if (subject === 'checkin' && id === model.id) {
          this.transitionTo(Ember.get(this, 'routeIfAlreadyAuthenticated'));
        } else {
          this._doAuth(model).then(function () {
            _this.transitionTo(Ember.get(_this, 'routeIfAlreadyAuthenticated'));
          });
        }
      } else {
        this._doAuth(model);
      }
    },
    _doAuth: function _doAuth(model) {
      var id = model.id,
          link_id = model.link_id;
      Ember.get(this, 'session').unidentify();
      Ember.get(this, 'session').set('data.start_path', "/checkin/".concat(id));
      return Ember.get(this, 'session').authenticate('authenticator:checkin', id, link_id).catch(function (reason) {
        console.error(reason);
        alert('Check-in link seems to be invalid. Try again, or contact support, support@fitbasepro.com');
      });
    }
  });

  _exports.default = _default;
});