define("fitbase/billing-session/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _relationships.default.belongsTo;

  var _default = _model.default.extend({
    cancelUrl: (0, _attr.default)('string'),
    successUrl: (0, _attr.default)('string'),
    stripeCustomerId: (0, _attr.default)('string'),
    sessionType: (0, _attr.default)('string'),
    portalUrl: (0, _attr.default)('string'),
    returnUrl: (0, _attr.default)('string'),
    user: belongsTo('user'),
    account: belongsTo('account')
  });

  _exports.default = _default;
});