define("fitbase/components/auth-registration/component", ["exports", "fitbase/utils/user-validations", "ember-cp-validations"], function (_exports, _userValidations, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.email': _userValidations.email,
    'model.password': _userValidations.password,
    'model.fullname': _userValidations.fullname
  });

  var _default = Ember.Component.extend(Validations, {
    tagName: 'form',
    submit: function submitAuthRegistrationForm(e) {
      e.preventDefault();
      this.doRegister();
    }
  });

  _exports.default = _default;
});