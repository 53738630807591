define("fitbase/components/auth-new-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MKTiJqJW",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[28,\"auth-error\",null,[[\"message\"],[[24,[\"error\"]]]]],false],[7,\"form\",false],[3,\"action\",[[23,0,[]],\"doNewPassword\"],[[\"on\"],[\"submit\"]]],[8],[1,[28,\"input-auth\",null,[[\"type\",\"label\",\"value\",\"inputName\",\"placeholder\",\"errors\",\"error\"],[\"password\",\"New password\",[24,[\"password\"]],\"new-password\",\"\",[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"args.model.password\"],null],\"messages\"],null],[23,1,[\"errors\",\"password\",\"firstObject\",\"message\"]]]]],false],[1,[28,\"input-auth\",null,[[\"type\",\"label\",\"value\",\"inputName\",\"placeholder\",\"errors\",\"error\"],[\"password\",\"Re-enter password\",[24,[\"confirmPassword\"]],\"new-password-confirm\",\"\",[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"args.model.confirmPassword\"],null],\"messages\"],null],[23,1,[\"errors\",\"confirmPassword\",\"firstObject\",\"message\"]]]]],false],[7,\"button\",true],[10,\"class\",\"button button--med button--primary\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"Save password\"],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/auth-new-password/template.hbs"
    }
  });

  _exports.default = _default;
});