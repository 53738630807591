define("fitbase/components/auth-login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rrMPz1jy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"input-auth\",null,[[\"type\",\"value\",\"label\",\"inputName\",\"placeholder\",\"errors\"],[\"email\",[24,[\"model\",\"email\"]],\"Email\",\"login-email\",\"magnus@trainerpro.com\",[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"model.email\"],null],\"messages\"],null]]]],false],[1,[28,\"input-auth\",null,[[\"type\",\"value\",\"label\",\"inputName\",\"placeholder\",\"errors\"],[\"password\",[24,[\"model\",\"password\"]],\"Password\",\"login-password\",\"Trainers never forget right?\",[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"model.password\"],null],\"messages\"],null]]]],false],[7,\"button\",true],[10,\"id\",\"auth-login-submit\"],[10,\"class\",\"button button--med button--action\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"Log In\"],[9],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/auth-login/template.hbs"
    }
  });

  _exports.default = _default;
});