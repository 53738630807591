define("fitbase/coach/settings/profile/controller", ["exports", "fitbase/utils/validations/user", "ember-changeset-validations"], function (_exports, _user, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showValidations: false,
    raven: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.validate = (0, _emberChangesetValidations.default)(_user.UserValidations);
    },
    hasChanges: Ember.computed('model.{hasBufferedChanges,hasDirtyAttributes}', function () {
      return this.get('model.hasBufferedChanges') || this.get('model.hasDirtyAttributes');
    }),
    actions: {
      save: function save(changeset) {
        var _this = this;

        changeset.save().catch(function (error) {
          if (error.code !== "InvalidError") {
            _this.raven.captureException(error);
          }
        });
      },
      validate: function validate() {
        return this.validate.apply(this, arguments);
      },
      cancel: function cancel()
      /*changeset*/
      {
        this.transitionToRoute('coach.settings.index');
      }
    }
  });

  _exports.default = _default;
});