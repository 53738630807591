define("fitbase/components/coach-client-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B9Rog63a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"coach-client-nav__avatar\"],[8],[7,\"div\",true],[10,\"class\",\"client-avatar\"],[8],[7,\"div\",true],[10,\"class\",\"client-avatar__image\"],[8],[9],[7,\"div\",true],[10,\"class\",\"client-avatar__status\"],[8],[1,[28,\"inline-svg\",[\"assets/images/icon-check-in\"],null],false],[9],[9],[9],[1,[28,\"nav-link\",null,[[\"route\",\"model\",\"icon\",\"isSubnav\",\"class\"],[\"coach.clients.client.index\",[24,[\"client\"]],\"assets/images/icon-profile-1\",true,\"nav\"]]],false],[1,[28,\"nav-link\",null,[[\"route\",\"model\",\"icon\",\"isSubnav\",\"class\"],[\"coach.clients.client.messages\",[24,[\"client\"]],\"assets/images/icon-chat\",true,\"nav\"]]],false],[1,[28,\"nav-link\",null,[[\"route\",\"model\",\"icon\",\"isSubnav\",\"class\"],[\"coach.clients.client.edit.checkin-form\",[24,[\"client\",\"id\"]],\"assets/images/icon-form-checkin\",true,\"nav\"]]],false],[1,[28,\"nav-link\",null,[[\"route\",\"model\",\"icon\",\"isSubnav\",\"class\"],[\"coach.clients.client.checkins.index\",[24,[\"client\"]],\"assets/images/icon-chart\",true,\"nav\"]]],false],[1,[28,\"nav-link\",null,[[\"route\",\"model\",\"icon\",\"isSubnav\",\"class\"],[\"coach.clients.client.checkins.pictures\",[24,[\"client\"]],\"assets/images/icon-photos\",true,\"nav\"]]],false],[1,[28,\"nav-link\",null,[[\"route\",\"model\",\"icon\",\"isSubnav\",\"class\"],[\"coach.clients.client.edit.index\",[24,[\"client\"]],\"assets/images/icon-client-cog\",true,\"nav\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/coach-client-nav/template.hbs"
    }
  });

  _exports.default = _default;
});