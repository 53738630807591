define("fitbase/components/coach-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["coach-nav"],
    navPrimary: null,
    navSecondary: null,
    isShowing: false,
    hasSharedForms: false,
    hasSharedLibrary: false,
    router: Ember.inject.service(),
    crispChat: Ember.inject.service(),
    // this is what you want it to be doing
    currentNavItem: Ember.computed('navPrimary', 'router.currentRouteName', function () {
      var _this = this;

      var nav = this.navPrimary.find(function (nav) {
        return _this.router.isActive(nav.route);
      });
      return nav ? nav : false;
    }),
    currentIconPath: Ember.computed('currentNavItem', function () {
      return this.currentNavItem ? this.currentNavItem.icon + '-on-dark' : '';
    }),
    _closeNav: function _closeNav() {
      this.set('isShowing', false);
    },
    actions: {
      closeNav: function closeNav() {
        Ember.run.next(this, this._closeNav);
        return true;
      },
      showHelp: function showHelp() {
        this.crispChat.open();
      }
    }
  });

  _exports.default = _default;
});