define("fitbase/coach/clients/client/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dylsOalr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"coach-client\"],[8],[1,[28,\"coach-client-nav\",null,[[\"client\",\"isShowingClientNav\"],[[24,[\"model\"]],true]]],false],[4,\"coach-client-main\",null,null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"scroll-wrap\"],[8],[7,\"div\",true],[10,\"class\",\"scroll\"],[8],[7,\"div\",true],[10,\"class\",\"page-content\"],[8],[7,\"div\",true],[10,\"class\",\"columns\"],[8],[1,[22,\"outlet\"],false],[9],[9],[9],[9]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/coach/clients/client/template.hbs"
    }
  });

  _exports.default = _default;
});