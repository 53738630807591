define("fitbase/components/billing-plan/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yD9+Kw4r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"billing-plan \",[23,0,[\"styleNamespace\"]]]]],[8],[7,\"div\",true],[10,\"class\",\"billing-plan__title\"],[8],[0,\"Economy Coach\"],[9],[7,\"div\",true],[10,\"class\",\"billing-plan__price\"],[8],[7,\"b\",true],[8],[0,\"$5.97\"],[9],[7,\"i\",true],[8],[0,\"per client per month\"],[9],[9],[7,\"div\",true],[10,\"class\",\"billing-plan__description\"],[8],[7,\"ul\",true],[10,\"class\",\"billing-plan__features\"],[8],[7,\"li\",true],[8],[7,\"img\",true],[10,\"src\",\"/assets/images/icon-radio-active.svg\"],[8],[9],[0,\"Check-ins\"],[9],[7,\"li\",true],[8],[7,\"img\",true],[10,\"src\",\"/assets/images/icon-radio-active.svg\"],[8],[9],[0,\"Messages\"],[9],[7,\"li\",true],[8],[7,\"img\",true],[10,\"src\",\"/assets/images/icon-radio-active.svg\"],[8],[9],[0,\"Picture comparing by date\"],[9],[7,\"li\",true],[10,\"class\",\"is-disabled\"],[8],[7,\"img\",true],[10,\"src\",\"/assets/images/icon-radio-inactive.svg\"],[8],[9],[0,\"Client billing\"],[9],[7,\"li\",true],[10,\"class\",\"is-disabled\"],[8],[7,\"img\",true],[10,\"src\",\"/assets/images/icon-radio-inactive.svg\"],[8],[9],[0,\"Client portal\"],[9],[9],[9],[9],[7,\"p\",true],[10,\"class\",\"p\"],[8],[0,\"First 2 clients are free. At 3 clients you'll be billed per client.\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/billing-plan/template.hbs"
    }
  });

  _exports.default = _default;
});