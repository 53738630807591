define("fitbase/components/coach-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aHvMcRL0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"coach-nav__primary\"],[8],[7,\"div\",true],[11,\"class\",[29,[\"nav-links nav-links--primary \",[28,\"if\",[[24,[\"navPrimaryIsOpen\"]],\"is-open\"],null]]]],[8],[1,[28,\"nav-link\",[\"Clients\",\"coach.clients.index\"],[[\"icon\",\"click\",\"activeRoutes\",\"badgeColor\",\"badgeCount\"],[\"assets/images/icon-people\",[28,\"action\",[[23,0,[]],\"closeNav\"],null],[28,\"array\",[\"coach.clients.client\",\"coach.clients.client.index\"],null],\"default\",[24,[\"countClients\"]]]]],false],[1,[28,\"nav-link\",[\"Account\",\"coach.settings\"],[[\"icon\",\"click\",\"activeRoutes\"],[\"assets/images/icon-cog\",[28,\"action\",[[23,0,[]],\"closeNav\"],null],[28,\"array\",[\"coach.settings\"],null]]]],false],[4,\"if\",[[24,[\"countLateClients\"]]],null,{\"statements\":[[1,[28,\"nav-link\",[\"Late clients\",\"coach.clients.late\"],[[\"icon\",\"click\"],[\"assets/images/icon-clock\",[28,\"action\",[[23,0,[]],\"closeNav\"],null]]]],false]],\"parameters\":[]},null],[4,\"if\",[[24,[\"paymentsActive\"]]],null,{\"statements\":[[1,[28,\"nav-link\",[\"Payments\",\"coach.payments\"],[[\"icon\",\"click\"],[\"assets/images/icon-creditcard\",[28,\"action\",[[23,0,[]],\"closeNav\"],null]]]],false]],\"parameters\":[]},null],[4,\"if\",[[24,[\"hasSharedLibrary\"]]],null,{\"statements\":[[1,[28,\"nav-link\",[\"Library\",\"coach.index\"],[[\"icon\",\"click\"],[\"assets/images/icon-folder\",[28,\"action\",[[23,0,[]],\"closeNav\"],null]]]],false]],\"parameters\":[]},null],[4,\"if\",[[24,[\"hasSharedForms\"]]],null,{\"statements\":[[1,[28,\"nav-link\",[\"Forms\",\"coach.forms\"],[[\"icon\",\"click\"],[\"assets/images/icon-forms\",[28,\"action\",[[23,0,[]],\"closeNav\"],null]]]],false]],\"parameters\":[]},null],[1,[28,\"nav-link\",[\"Help\"],[[\"icon\",\"active\",\"click\"],[\"assets/images/icon-support\",false,[28,\"action\",[[23,0,[]],\"showHelp\"],null]]]],false],[9],[9],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/components/coach-nav/template.hbs"
    }
  });

  _exports.default = _default;
});