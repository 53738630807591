define("fitbase/components/nav-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend({
    router: Ember.inject.service(),
    activeRoutes: false,
    classNameBindings: ['isSubnav', 'isActiveAlt:active', 'isBadged', 'badgeColorClass'],
    attributeBindings: ['badgeCount:data-count'],
    isBadged: Ember.computed('badgeCount', function () {
      return !!this['badgeCount'];
    }),
    badgeColorClass: Ember.computed('badgeColor', function () {
      return this.badgeColor ? '--' + this.badgeColor : '';
    }),
    isActiveAlt: Ember.computed('active', 'router.currentRoute', 'route', 'activeRoutes', 'disabled', function () {
      var _this = this;

      var isActiveAlternate = false;

      if (this.activeRoutes && this.activeRoutes.length > 0) {
        isActiveAlternate = !!this.activeRoutes.find(function (routeName) {
          return _this.router.isActive(routeName);
        });
      }

      return isActiveAlternate;
    }),
    iconPath: Ember.computed('icon', 'isActiveAlt', function () {
      return this.active && this.isSubnav ? "".concat(this.icon, "-on-dark") : this.icon;
    })
  });

  _exports.default = _default;
});