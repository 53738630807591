define("fitbase/coach/clients/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QC8I/XU4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"scroll-wrap\"],[8],[7,\"div\",true],[10,\"class\",\"scroll\"],[8],[7,\"div\",true],[10,\"class\",\"page-content columns\"],[8],[4,\"if\",[[24,[\"clients\"]]],null,{\"statements\":[[1,[28,\"clients-nav\",null,[[\"allClients\",\"isIndex\"],[[24,[\"clients\"]],true]]],false]],\"parameters\":[]},{\"statements\":[[7,\"main\",true],[10,\"class\",\"column column--center client\"],[8],[7,\"div\",true],[10,\"class\",\"clients__intro\"],[8],[7,\"div\",true],[10,\"class\",\"clients-empty\"],[8],[9],[7,\"h1\",true],[10,\"class\",\"h1\"],[8],[0,\"Looks like you don't have any clients\"],[9],[4,\"link-to\",null,[[\"class\",\"route\"],[\"button button--big button--action clients__create\",\"coach.clients.new\"]],{\"statements\":[[0,\"Create a new client\"]],\"parameters\":[]},null],[9],[9]],\"parameters\":[]}],[9],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fitbase/coach/clients/index/template.hbs"
    }
  });

  _exports.default = _default;
});