define("fitbase/lib/field-schema-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember$computed = Ember.computed,
      empty = _Ember$computed.empty,
      and = _Ember$computed.and,
      or = _Ember$computed.or,
      not = _Ember$computed.not;
  var FieldSchema = Ember.Object.extend({
    slug: null,
    label: null,
    unit: null,
    help: null,
    noLabel: empty('label'),
    noUnit: empty('unit'),
    image: null,
    isEmpty: and('noLabel', 'noUnit'),
    isInvalid: or('noLabel', 'noUnit'),
    isValid: not('isInvalid'),
    toString: function toString() {
      return this.get('isValid') ? JSON.stringify(this) : "";
    },
    inputType: Ember.computed('unit', function () {
      var unit = this.get('unit');
      var input = null;

      for (var inputType in FieldSchema.unitInputMap) {
        var units = FieldSchema.unitInputMap[inputType];

        if (units.contains(unit)) {
          input = inputType;
        }
      }

      return input;
    })
  });
  FieldSchema.reopenClass({
    unitOptions: Ember.A(["inches", "centimeter", "pounds", "kilos", "minutes", "hours", "short text", "notes", "feelings", "date", "image"]),
    unitInputMap: {
      'number': ['inches', 'centimeters', 'pounds', 'kilos', 'minutes', 'hours'],
      'text': ['short text'],
      'textarea': ['notes'],
      'feeling': ['feelings'],
      'date': ['date'],
      'upload': ['image']
    }
  });
  var _default = FieldSchema;
  _exports.default = _default;
});